.login__card{
    width: 30%;
    margin-left: auto;
    margin-right: auto;
   margin-top: 100px;
   box-shadow: 0px 0.344px 12px rgb(238, 238, 238);
   padding: 2rem;
   border: 1px solid lightgray;
   border-radius: 5px;
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
}

.login__card h2{
    text-align: center;
    font-size: 2rem;
}


.input__wrapper,.input__inline__wrapper{
    margin-bottom: 1rem;
    width: 80%;
  
}

.input__inline__wrapper{
    display: flex;
    justify-content: space-between;
}


.login__card img{
    width: 100px;
}


.input__info{
    text-align: center;
    font-size: 13px;
    color:gray;
    margin-bottom: 1rem;
}

.input__info a{
    text-decoration: none;
}

.input__info a:hover{
    text-decoration: underline;
   
}